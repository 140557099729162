import React from "react";
import treemore from "../../images/treemore.png";
import master from "../../images/master-tea-coffe.png";
import brute from "../../images/brutebonen2.png";
import ambt from "../../images/ambt_logo.jpeg";

const Client = () => {
    return (
        <div
            id="Clients"
            class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
        >
            <div class="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
                <h2 class="text-xl font-semibold md:text-2xl md:leading-tight text-gray-800 dark:text-gray-200">
                    For sale at:
                </h2>
            </div>

            <div class="grid grid-cols-2 sm:grid-cols-4 content-center md:grid-cols-5 gap-3 lg:gap-6">
                <div class="p-4 text-lg md:p-7 bg-green rounded-lg dark:bg-slate-800">
                    {/* eslint-disable */}
                    <a
                        className="text-lg"
                        target="_blank"
                        href="https://www.treemore.eu/shop"
                    >
                        <img src={treemore} alt="treemore" />
                    </a>
                </div>
                <div class="p-4 text-lg md:p-7 bg-green rounded-lg dark:bg-slate-800">
                    <a
                        className="text-lg"
                        target="_blank"
                        href="https://www.mastersteaandcoffee.nl/"
                    >
                        <img src={master} alt="master of thee and coffee" />
                    </a>
                </div>
                <div class="p-4 text-lg md:p-7 bg-green rounded-lg dark:bg-slate-800">
                    <a
                        className="text-lg"
                        target="_blank"
                        href="https://brutebonen.nl/"
                    >
                        <img src={brute} alt="brute bonen logo" />
                    </a>
                </div>
                <div class="p-4 text-lg md:p-7 bg-green rounded-lg dark:bg-slate-800">
                    <a
                        className="text-lg"
                        target="_blank"
                        href="https://hethalveambt.nl/"
                    >
                        <img src={ambt} alt="Branderij Het Halve Ambt logo" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Client;
